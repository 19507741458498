import tw, { styled } from "twin.macro"

import { H3, P } from "../../styled/Text"
import { StyledButton } from "../../styled/Button"
import { StyledRow } from "../../styled/Row"
import { StyledColumn } from "../../styled/Column"
import { StyledResponsive } from "../../styled/Responsive"

export { StyledContainer as Container } from "../../styled/Container"
export { StyledGridColumn as GridColumn } from "../../styled/Grid"
export { StyledResponsive as Responsive } from "../../styled/Responsive"
export { StyledUl, StyledLi } from "../../styled/List"

export const Row = tw(StyledRow)`md:-mx-2 lg:-mx-4 md:w-auto`
export const Column = tw(StyledColumn)`md:px-2 lg:px-4`
export const Title = tw(H3)`text-md md:text-lg mb-6 md:mb-4 lg:text-lg mt-10 first:mt-0`
export const MentorsContainer = tw(StyledResponsive)`bg-grey-pale py-12`
export const FieldTitle = tw(P)`mt-1 mb-2 font-bold`
export const ActionTitle = tw(P)`mt-8 mb-4 text-center`
export const ActionRow = tw(StyledRow)`w-auto -mx-2`
export const ActionColumn = tw(StyledColumn)`px-2`
export const ActionButton = tw(StyledButton)`shadow-none w-full text-center`
export const AvailabilityButton = styled(StyledButton)`
  ${tw`w-full`}
  background: linear-gradient(270deg, #fe6086 0%, #ff007d 100%);
`
export const ContentContainer = tw.div`w-full flex flex-col gap-7`
export const ContentText = tw.p`m-0 text-grey-dark`
export const Divider = tw.p`m-0 text-grey-dark`
export const SpotsContainer = tw.div`flex gap-2`
export const SpotsProgressWrapper = tw.div`w-8`
export const SpotsTextPrimary = tw.span`font-bold text-grey-dark`
export const SpotsTextSecondary = tw.span`text-grey-mid font-normal`
export const MentorDetailsCareerHistorySideWrapper = tw.div`hidden md:block mb-8`
export const LinkButton = styled("button")`
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  ${tw`underline outline-none! hover:no-underline`}
`
