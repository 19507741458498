import React from "react"
import { FilterItem, ToggleLabel, DotOn, DotOff, ToggleBackgroundOn, ToggleBackgroundOff, ToggleWrapper } from "./MentorSearchFilters.styled"

export const ToggleRefinement = ({ currentRefinement, label, refine }) => (
  <FilterItem>
    <ToggleLabel
      onClick={event => {
        event.preventDefault()
        refine(!currentRefinement)
      }}
      htmlFor={label}
    >
      <ToggleWrapper>
        <input id={label} checked={currentRefinement} type="checkbox" className="sr-only" />

        {currentRefinement ? (
          <>
            <ToggleBackgroundOn /> <DotOn />
          </>
        ) : (
          <>
            <ToggleBackgroundOff /> <DotOff />
          </>
        )}
      </ToggleWrapper>
    </ToggleLabel>
  </FilterItem>
)
