import tw, { styled } from "twin.macro"
import { StyledButton } from "../../styled/Button"
import { StyledCard } from "../../styled/Card"

export const MentorSearchResultsList = styled.div`
  ${tw`gap-6 grid grid-cols-1 md:grid-cols-2`}
`
export const ResultsFooter = tw.div`flex justify-around mt-4`
export const LoadMoreButton = styled(StyledButton)`
  ${tw`lg:ml-2 my-8 mt-12`}
`
export const HitCard = styled(StyledCard)`
  ${tw`h-full text-center text-left flex flex-wrap p-6`}
`
export const HitCardWrapper = tw.div`flex`
export const HitCardImage = tw.img`inline-block rounded-full object-cover flex-shrink-0 w-30 h-30`
export const HitCardContentWrapper = tw.div`w-full pl-4`
export const HitCardWishlistButton = tw.div`absolute right-6`
