import React from "react"

import { withMentorCard } from "./withMentorCard"
import { CardLink, Title, Content, Subtitle, WishlistButton, ViewProfileBtn } from "./MentorCard.styled"
import { Icon } from "../Icon/Icon"
import { HitCard, HitCardContentWrapper, HitCardImage, HitCardWishlistButton, HitCardWrapper } from "./MentorSearchResults.styled"

export const MentorHitCard = withMentorCard(
  ({ link, image, title, content, subtitle, page: { profileLink }, handleSavedItem, existsInSavedItems }: Props): JSX.Element => {
    return (
      <CardLink horizontal={true} to={link ? link : null}>
        <HitCard horizontal={true} shadow={`medium`}>
          <HitCardWrapper>
            <HitCardImage src={image} />
            <HitCardContentWrapper>
              <Title horizontal={true}>{title}</Title>
              <Content horizontal={true}>{content?.length > 50 ? content?.substring(0, 45).concat("...") : content || ""}</Content>
              <Subtitle horizontal={true}>{subtitle?.length > 25 ? subtitle?.substring(0, 20).concat("...") : subtitle || ""}</Subtitle>
              <ViewProfileBtn theme={`secondary`} colour={`outline`} size={`small`}>
                {profileLink}
              </ViewProfileBtn>
            </HitCardContentWrapper>
            <HitCardWishlistButton>
              <WishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
                <Icon name={`heart`} size={`ml`} />
              </WishlistButton>
            </HitCardWishlistButton>
          </HitCardWrapper>
        </HitCard>
      </CardLink>
    )
  }
)

export interface Props {
  content?: string
  image?: any
  link: string
  size?: string
  subtitle?: string
  title: string
  page?: any
  existsInSavedItems?: boolean
  handleSavedItem?: (any) => void
}
