import React, { useState } from "react"

import { connectToggleRefinement, connectCurrentRefinements } from "react-instantsearch-dom"
import { ClearRefinements } from "./MentorSearchClearRefinements"
import {
  Title,
  Card,
  Section,
  FilterCard,
  ShowFiltersButton,
  FilterCardHeader,
  FilterCardTitle,
  AvailabilityHeader,
} from "./MentorSearchFilters.styled"
import { MentorSearchFilterSection } from "./MentorSearchFilterSection"
// import { Stats } from "./MentorSearchStats"
import { ToggleRefinement } from "./MentorSearchToggle"

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements)
// const CustomStats = connectStats(Stats)
const CustomToggle = connectToggleRefinement(ToggleRefinement)

export const MentorSearchFilters = ({}) => {
  const [isHidden, setIsHidden] = useState(true)

  return (
    <>
      <ShowFiltersButton theme={`primary`} colour={`default`} size={`medium`} onClick={() => setIsHidden(!isHidden)}>
        {isHidden ? "Show Filters" : "Hide Filters"}
      </ShowFiltersButton>
      <Card shadow={`medium`} horizontal={true} isHidden={isHidden}>
        <FilterCard>
          <FilterCardHeader>
            <FilterCardTitle>Filters</FilterCardTitle>
            {/* <CustomStats /> */}
            <CustomClearRefinements />
          </FilterCardHeader>

          <Section>
            <AvailabilityHeader>
              <Title>Available this month</Title>
              <CustomToggle attribute="available" label="availability" value={true} />
            </AvailabilityHeader>
          </Section>

          <MentorSearchFilterSection title="Industry" attribute={"industry"} />

          <MentorSearchFilterSection title="Skills" attribute={"skills"} />

          <MentorSearchFilterSection title="Location" attribute={"countryAndCity"} />
        </FilterCard>
      </Card>
    </>
  )
}
