import tw, { styled } from "twin.macro"
import { H1 } from "../../styled/Text"
import { StyledInput } from "../../styled/Forms"
import { Icon } from "../Icon/Icon"

export { StyledResponsive as Responsive } from "../../styled/Responsive"

export const Title = tw(H1)`text-white mb-4 lg:text-4xl font-bold`
export const InputWrapper = tw.div`bg-blend md:bg-transparent w-full flex items-center justify-between rounded-lg md:border-white md:border pr-6`
export const Input = tw(StyledInput)`bg-transparent border-0 text-white shadow-none py-3 md:py-2 pl-6 pr-4 w-full`
export const StyledIcon = tw(Icon)`absolute right-2`
export const Clear = tw.button`mr-4 focus:outline-none active:outline-none`
export const NoResultsWrapper = tw.div`text-center text-md mt-6 text-gray-500`
export const HitsWrapper = styled.div`
  ${tw`lg:ml-10`} width: calc(100% - 306px);
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`
export const FiltersWrapper = styled.div`
  ${tw`mb-10 lg:mb-0`} width: 290px;

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`
export const SearchWrapper = tw.div`flex flex-col lg:flex-row my-10 items-start`
