import React, { useEffect, useRef, useState } from "react"
import { useCore } from "../../hooks/useCore"
import qs from "qs"

export const withMentorListing = Component =>
  React.memo(({ name = "MentorListing", location, page }) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const title = page?.title
    const content = sanityContent(page?.content)

    const DEBOUNCE_TIME = 400
    const createURL = state => `?${qs.stringify(state)}`
    const urlToSearchState = ({ search }) => qs.parse(search.slice(1))
    const [searchState, setSearchState] = useState(urlToSearchState(location))
    const debouncedSetStateRef = useRef(null)

    const onSearchStateChange = updatedSearchState => {
      clearTimeout(debouncedSetStateRef.current)

      debouncedSetStateRef.current = setTimeout(() => {
        let url = createURL(updatedSearchState)
        window.history.pushState(updatedSearchState, null, url)
      }, DEBOUNCE_TIME)

      setSearchState(updatedSearchState)
    }

    useEffect(() => {
      setSearchState(urlToSearchState(location))
    }, [location])

    Component.displayName = name
    return <Component title={title} searchState={searchState} onSearchStateChange={onSearchStateChange} createURL={createURL} content={content} />
  })
