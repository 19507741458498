import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter"

export const useSearchTypesense = (searchFields: string) => {
  const adapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: `${process.env.GATSBY_TYPESENSE_API_KEY}`,
      nodes: [{ host: `${process.env.GATSBY_TYPESENSE_HOST}`, port: 443, protocol: "https" }],
      cacheSearchResultsForSeconds: 2 * 60,
    },
    additionalSearchParameters: {
      query_by: searchFields,
    },
  })

  return { searchClient: adapter.searchClient }
}
