import React from "react"
import { Configure } from "react-instantsearch-dom"
import { routeResolver } from "../Wrappers/Helpers"
import { MentorHitCard } from "./MentorHitCard"
import { LoadMoreButton, ResultsFooter, MentorSearchResultsList } from "./MentorSearchResults.styled"

export const MentorSearchResults = ({ hits, hasMore, refineNext }) => (
  <>
    <MentorSearchResultsList>
      {hits.map(hit => {
        const { objectID, id, title, role, company, content, image, slug } = hit

        return (
          <MentorHitCard
            key={objectID}
            id={id}
            size={`full`}
            title={title}
            content={role}
            subtitle={company || content?.careers?.[0]?.company}
            image={image}
            link={routeResolver({ item: { slug, title }, type: `mentor` })}
          />
        )
      })}
    </MentorSearchResultsList>
    <Configure hitsPerPage={40} />
    <ResultsFooter>
      <LoadMoreButton onClick={refineNext} disabled={!hasMore} theme={"primary"} size={"medium"} colour={"outline"}>
        Load more
      </LoadMoreButton>
    </ResultsFooter>
  </>
)
