import React from "react"

import { useSearchTypesense } from "../../hooks/useSearchTypesense"

import { InstantSearch, connectInfiniteHits, connectSearchBox, connectStateResults } from "react-instantsearch-dom"

import { Hero } from "../Section/Hero/Hero"
import { Loading } from "../Loading/Loading"
import { SearchBox } from "./MentorSearchBox"
import { MentorSearchFilters } from "./MentorSearchFilters"
import { MentorSearchResults } from "./MentorSearchResults"
import { withMentorListing } from "./withMentorListing"

import { Container } from "./MentorDetails.styled"
import { HitsWrapper, SearchWrapper, FiltersWrapper, Title, NoResultsWrapper } from "./MentorListing.styled"

const { searchClient } = useSearchTypesense("title,location,country,countryAndCity,role,company,seeking,skills")

const CustomSearchBox = connectSearchBox(SearchBox)
const CustomHits = connectInfiniteHits(MentorSearchResults)

const ContentWrapper = connectStateResults(({ isSearchStalled, searchResults }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0

  return isSearchStalled ? (
    <Loading loading={true} />
  ) : hasResults ? (
    <>
      <SearchWrapper>
        <FiltersWrapper>
          <MentorSearchFilters />
        </FiltersWrapper>
        <HitsWrapper>
          <CustomHits />
        </HitsWrapper>
      </SearchWrapper>
    </>
  ) : (
    <NoResultsWrapper>No results found</NoResultsWrapper>
  )
})

export const MentorListing = withMentorListing(({ title, searchState, onSearchStateChange, createURL }): JSX.Element => {
  return (
    <>
      <InstantSearch
        indexName="mentors"
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Hero colour={`secondary`} size={`small`}>
          <Container width={`md`}>
            <Title>{title}</Title>
            <CustomSearchBox />
          </Container>
        </Hero>

        <Container condense={`<md`} width={`xxl`}>
          <ContentWrapper />
        </Container>
      </InstantSearch>
    </>
  )
})

export interface Props {
  title?: string
}
