import tw, { styled } from "twin.macro"
import { H4, P } from "../../styled/Text"
import { StyledCard } from "../../styled/Card"
import { StyledButton } from "../../styled/Button"

export const Card = styled(StyledCard)`
  ${tw`hidden lg:flex lg:mt-0 px-5 py-0 flex-wrap`}
  ${({ isHidden }) => (isHidden ? tw`hidden` : tw`flex mt-4`)}
`
export const Title = tw(P)`lg:text-md`
export const FilterItem = styled.div`
  ${tw`flex items-start cursor-pointer`}
  & span {
    position: relative;
    margin-top: 5px;
  }
`
export const FilterItemChild = styled(FilterItem)`
  ${tw`ml-4 flex-col`}
`
export const FilterList = styled.div`
  ${({ isCollapsed }) => (isCollapsed ? tw`hidden` : tw`mb-4`)}
`
export const Section = styled.div`
  ${tw`border-t last:border-b last:mb-8 border-grey-lighter`}
`
export const Label = styled.label`
  ${tw`text-sm inline-block text-grey-dark ml-2 cursor-pointer hover:text-black`} padding-top: 2.5px;
`
export const LabelGroup = styled.label`
  ${tw`font-bold text-sm inline-block text-grey-dark ml-2 m-0 mt-2`} padding-top: 2.5px;
`
export const ClearFilter = tw.button`underline text-xs text-grey-mid`
export const FilterHeader = tw.div`flex justify-between py-3 cursor-pointer`
export const AvailabilityHeader = tw.div`flex cursor-default py-3 justify-between`
export const FilterCard = tw.div`w-full`
export const FilterCardTitle = tw(H4)`my-3 lg:text-md`
export const FilterCardHeader = tw.div`flex mt-4 justify-between`
export const FilterHeaderTitle = tw.div`flex justify-between`
export const FilterSectionCounter = tw.div`text-sm self-center ml-2 text-green`
export const ShowFiltersButton = styled(StyledButton)`
  ${tw`w-full lg:hidden`}
`
export const ToggleWrapper = tw.div`
  ${`relative mt-2 cursor-pointer`}
`
export const ToggleLabel = styled.label`
  ${`flex items-center`}
`
export const DotOn = tw.div`
  ${`absolute bg-white w-5 h-5 rounded-full shadow -right-1 -top-4`}
`
export const DotOff = tw.div`
  ${`absolute bg-white w-5 h-5 rounded-full shadow -left-1 -top-4`}
`
export const ToggleBackgroundOn = tw.div`${`w-10 h-3 rounded-full bg-green`}`
export const ToggleBackgroundOff = tw.div`${`w-10 h-3 rounded-full bg-grey-light`}`
