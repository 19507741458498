import React from "react"
import { graphql } from "gatsby"

import { MentorListing as Page } from "../components/Mentor/MentorListing"
import { withAuthentication } from "../hoc/withAuthentication"
import { withMember } from "../hoc/withMember"

export const query = graphql`
  query {
    page: sanityPageMentors {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default withAuthentication(withMember(({ data, ...props }): JSX.Element => <Page {...props} {...data} />))
