import React from "react"

import { Clear, Input, InputWrapper, StyledIcon } from "./MentorListing.styled"

export const SearchBox = ({ currentRefinement, refine }) => {
  return (
    <InputWrapper>
      <Input
        variant={`inherit`}
        type="text"
        placeholder="Search mentors"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
      />

      {currentRefinement !== "" && (
        <Clear type="reset" onClick={() => refine("")}>
          <StyledIcon name={`cross`} size={`s`} colour={`white`} />
        </Clear>
      )}

      <StyledIcon name={`search`} size={`m`} colour={`white`} />
    </InputWrapper>
  )
}
